const reportFields = {
  granteeName: 'Recipient name(s)',
  programType: 'Program type(s)',
  cdiGranteeName: 'CDI recipient(s)',
  multiGranteeActivities: 'Multi-recipient activities',
  nonGranteeActivity: 'Other entity activity',
  sourceOfRequest: 'Who requested the TTA?',
  reasons: 'Reason(s)',
  startDate: 'Start Date',
  endDate: 'End Date',
  duration: 'Duration in hours',
  otherSpecialists: 'Other Specialist(s) involved',
  format: 'How was TTA provided?',
  tTa: 'Was this Training or Technical Assistance',
  topics: 'Topic(s) covered',
  otherTopics: 'Other topics covered',
  granteeParticipants: 'Recipient participant(s)',
  nonGranteeParticipants: 'Other entity participant(s)',
  participants: 'Participants',
  numberOfParticipants: 'Number of participants',
  targetPopulations: 'Target populations addressed',
  resourcesUsed: 'OHS / ECLKC resources',
  nonOhsResources: 'Non-ECLKC resources',
  contextForThisActivity: 'Context for this activity',
  goal1: 'Goal 1',
  granteesLearningLevelGoal1: "Recipient's learning level Goal 1",
  objective11: '1st objective for Goal 1',
  objective11Status: '1st Objective for Goal 1 status',
  objective12: '2nd objective for Goal 1',
  objective12Status: '2nd objective for Goal 1 status',
  goal2: 'Goal 2',
  granteesLearningLevelGoal2: "Recipient's learning level Goal 2",
  objective21: '1rst objective for Goal 2',
  objective21Status: '1rst objective for Goal 2 status',
  objective22: '2nd objective for Goal 2',
  objective22Status: '2nd objective for Goal 2 status',
  ttaProvidedAndGranteeProgressMade: 'TTA Provided',
  specialistFollowUpTasksObjectives: 'Specialist follow-up',
  granteeFollowUpTasksObjectives: 'Recipient follow-up',
  additionalNotesForThisActivity: 'Additional notes for this activity',
  manager: 'Manager',
  managerApproval: 'Manager approval',
  comments: 'Comments',
  created: 'Time Created',
  createdBy: 'Created By',
  modified: 'Time Modified',
  modifiedBy: 'Modified By',
};

export default reportFields;
