import log from './pages/log';
import nextSteps from './pages/nextSteps';
import supportingAttachments from './pages/supportingAttachments';

const pages = [
  log,
  supportingAttachments,
  nextSteps,
];

export default pages;
